import React from 'react';
import { HashRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
// contexts
import { CardsContentProvider } from '../contexts/CardsContentContext';
// pages
import {
  InternshipsPage,
  ProjectsPage,
  WorksPage,
  ExtracurricularsPage,
  AwardsPage,
} from '../pages/IndexPage/Index';

export default function Routes() {
  return (
    <Router>
      <CardsContentProvider>
        <Switch>
          <Route exact path='/' component={InternshipsPage}>
            <Redirect to='/internships'/>
          </Route>
          <Route exact path='/internships' component={InternshipsPage}/>
          <Route exact path='/projects' component={ProjectsPage}/>
          <Route exact path='/work' component={WorksPage}/>
          <Route exact path='/extracurriculars' component={ExtracurricularsPage}/>
          <Route exact path='/awards' component={AwardsPage}/>
        </Switch>
      </CardsContentProvider>
    </Router>
  )
}
