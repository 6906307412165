import React from 'react';
// components
import Intro from '../../components/Intro/Intro';
import Navigation from '../../components/Navigation/Navigation';
import Cards from '../../components/Cards/Cards';
import Footer from '../../components/Footer/Footer';
// styles
import styles from './Index.module.css';

export function InternshipsPage() {
  return (
    <div className={styles.container}>
      <div className={styles.intro}>
        <Intro/>
        <Navigation currentSection={'internships'}/>
      </div>
      <Cards cards={'internships'}/>
      <Footer/>
    </div>
  );
}

export function ProjectsPage() {
  return (
    <div className={styles.container}>
      <div className={styles.intro}>
        <Intro/>
        <Navigation currentSection={'projects'}/>
      </div>
      <Cards cards={'projects'}/>
      <Footer/>
    </div>
  );
}

export function WorksPage() {
  return (
    <div className={styles.container}>
      <div className={styles.intro}>
        <Intro/>
        <Navigation currentSection={'work'}/>
      </div>
      <Cards cards={'work'}/>
      <Footer/>
    </div>
  );
}

export function ExtracurricularsPage() {
  return (
    <div className={styles.container}>
      <div className={styles.intro}>
        <Intro/>
        <Navigation currentSection={'extracurriculars'}/>
      </div>
      <Cards cards={'extracurriculars'}/>
      <Footer/>
    </div>
  );
}

export function AwardsPage() {
  return (
    <div className={styles.container}>
      <div className={styles.intro}>
        <Intro/>
        <Navigation currentSection={'awards'}/>
      </div>
      <Cards cards={'awards'}/>
      <Footer/>
    </div>
  );
}
