import React from 'react';
// styles
import styles from './Footer.module.css';

export default function Footer() {
  return (
    <div className={styles.container}>
      <div className={styles.footerLinks}>
        <div className={styles.footerLink}>
          <a href="https://github.com/sheezaaziz" target="_blank" rel="noreferrer">
            <i className="fab fa-github"/>
              <span className={styles.linkText}>
                GitHub
              </span>
            <i className="ext-link fa-xs fas fa-external-link-alt"/>
          </a>
        </div>
        <div className={styles.footerLink}>
          <a href="https://www.linkedin.com/in/sheeza-aziz/" target="_blank" rel="noreferrer">
            <i className="fab fa-linkedin-in"/>
            <span className={styles.linkText}>
              LinkedIn
            </span>
            <i className="ext-link fa-xs fas fa-external-link-alt"/>
          </a>
        </div>
        <div className={styles.footerLink}>
          <a href="mailto:sheeza.aziz@utoronto.ca" target="_blank" rel="noreferrer">
            <i className="far fa-paper-plane"/>
              <span className={styles.linkText}>
                Email
              </span>
            <i className="ext-link fa-xs fas fa-external-link-alt"/>
          </a>
        </div>
      </div>
      <p className={styles.cred}>Made with 💜 by Sheeza Aziz</p>
    </div>
  )
}
