import React, { useContext, createContext, useState, useEffect } from 'react';

const CardsContentContext = createContext();

export function useCardsContent() {
  return useContext(CardsContentContext);
}

export function CardsContentProvider({ children }) {
  const [internships, setInternships] = useState([
    {
      tech: 'MLH',
      iconClass: 'fas fa-laptop-code',
      title: 'Open Source Fellow',
      descr: 'Incoming Open Source Fellow.',
      date: 'Fall 2022',
      link: 'https://fellowship.mlh.io/programs/open-source'
    },
    {
      tech: 'Tesla',
      iconClass: 'fas fa-car-side',
      title: 'Software Engineer',
      descr: 'Building & maintaining version 2 of a web application that tracks internal supply chain progress.',
      date: 'Dec 2021 – Jun 2022',
      link: 'https://www.tesla.com/'
    },
    {
      tech: 'IBM',
      iconClass: 'fas fa-server',
      title: 'IoT & AI Developer',
      descr: 'Building an iOS mobile app using SwiftUI, CoreData, Vision, & JSON parsing with decodable.',
      date: 'Sep 2021 – Dec 2021',
      link: 'https://www.ibm.com/ca-en'
    },
    {
      tech: 'dApp Technology Inc.',
      iconClass: 'fab fa-ethereum',
      title: 'Front-End Developer',
      descr: 'Building responsive, component-based webpages with React.js.',
      date: 'Mar 2021 ⁠– Dec 2021',
      link: 'https://dapp-inc.com/'
    },
    {
      tech: 'BlackBerry',
      iconClass: 'fas fa-shield-alt',
      title: 'Security Test Automation',
      descr: 'Automating tests with Jenkins CI/CD pipeline.',
      date: 'Jan 2021 ⁠– Apr 2021',
      link: 'https://www.blackberry.com/us/en'
    }
  ]);
  const [projects, setProjects] = useState([
    {
      tech: 'React, JavaScript, Firebase',
      iconClass: 'fas fa-paint-brush',
      title: 'Layers of Love',
      descr: 'Community collage making web-application featuring the artwork of 5 Toronto-based artists.',
      date: 'Jul 2021',
      link: 'https://layersoflove.ca/'
    },
    {
      tech: 'Python, Flask',
      iconClass: 'fas fa-chalkboard-teacher',
      title: 'UofT Course Website',
      descr: 'Responsive mock-up providing different authorization access to instructor/student accounts.',
      date: 'Apr 2020',
      link: 'https://github.com/sheezaaziz/UofT-Course-Website',
    }
  ]);
  const [workExp, setWorkExp] = useState([
    {
      tech: 'University of Toronto',
      iconClass: 'fas fa-school',
      title: 'IITS Student Helpdesk Support Representative',
      descr: 'Providing Tier 1 customer and technical support services.',
      date: 'Sep 2019 ⁠– Present',
      link: 'https://www.utsc.utoronto.ca/home/',
    },
    {
      tech: 'hEr VOLUTION',
      iconClass: 'fas fa-child',
      title: 'Student Program Coordinator',
      descr: 'Appointed liason between students and founder. Mentor, leader, primary point of contact.',
      date: 'Aug 2019',
      link: 'https://www.hervolution.org/',
    }
  ]);
  const [extracurriculars, setExtracurriculars] = useState([
    {
      tech: 'Women in CS, Stats, & Maths | University of Toronto',
      iconClass: 'fas fa-female',
      title: 'President',
      descr: 'Planning, hosting, and/or instructing events to encourage and empower women into the field of CSM.',
      date: 'Apr 2021 ⁠– Present',
      link: 'https://www.wicsm.ca/'
    },
    {
      tech: 'hEr VOLUTION',
      iconClass: 'fas fa-child',
      title: 'Vice Chair of Youth Committee',
      descr: 'Communicating youth-based insight to the board, voting on board decisions',
      date: 'Jan 2021 ⁠– Jul 2021',
      link: 'https://www.hervolution.org/',
    },
    {
      tech: 'Canada Learning Code',
      iconClass: 'fab fa-canadian-maple-leaf',
      title: 'Instructor',
      descr: 'Instructing kids, teens, and adults on fundamental coding skills.',
      date: 'Apr 2021 ⁠– Present',
      link: 'https://www.canadalearningcode.ca/about-us/'
    },
    {
      tech: 'FeminSTEM',
      iconClass: 'far fa-lightbulb',
      title: 'Technology Mentor',
      descr: 'Created, instructed, and mentored a beginners Python programming workshop.',
      date: 'Aug 2020',
      link: 'https://www.feminstem.ca/'
    },
    {
      tech: 'Scarborough Campus Student Union',
      iconClass: 'fas fa-users',
      title: 'Frosh Logistics Leader',
      descr: 'Created safe, inclusive, and fun space for incoming freshmen at the University of Toronto.',
      date: 'Aug 2019',
      link: 'https://www.scsu.ca/'
    },
    {
      tech: 'TAKÉ, Junior Achievement',
      iconClass: 'fas fa-briefcase',
      title: 'Co-Founder, Vice President of Marketing',
      descr: 'Oversaw business operations from the ground up of a start-up, designed, delivered, and pitched company brand.',
      date: 'Oct 2016 ⁠– May 2017',
      link: 'https://www.jacanada.org/'
    }
  ]);
  const [awards, setAwards] = useState([
    {
      tech: 'IBM',
      iconClass: 'fas fa-server',
      title: '1st Place',
      descr: 'Pitched a mobile-app prototype that connects users to their nearest medical centre.',
      date: 'Aug 2020',
      link: 'https://www.ibm.com/ca-en'
    },
    {
      tech: 'Royal Bank of Canada',
      iconClass: 'fas fa-money-check-alt',
      title: '1st Place',
      descr: 'Pitched a business idea of an app that matches Tutors with Tutees based on a matching algorithm.',
      date: 'Feb 2019',
      link: 'https://www.rbcroyalbank.com/personal.html'
    },
    {
      tech: 'ElleHacks',
      iconClass: 'fas fa-code',
      title: 'Top 5 Finalists',
      descr: 'Demoed a CLI of an app that matches Tutors with Tutees based on a matching algorithm.',
      date: 'Feb 2019',
      link: 'https://ellehacks.com/'
    }
  ]);

  const value = {
    internships,
    projects,
    workExp,
    extracurriculars,
    awards,
  }

  return (
    <CardsContentContext.Provider value={value}>
      {children}
    </CardsContentContext.Provider>
  )
}
