import React from 'react';
// components
import Navigation from '../Navigation/Navigation';
// styles
import styles from './Intro.module.css';

export default function Intro() {
  return (
    <div className={styles.container}>
      <div className={styles.introText}>
        <h1>Hi 👋, I'm Sheeza Aziz</h1>
        <p className={styles.descr}>I am a Software Developer and Student based in Toronto, Canada.</p>
        <p className={styles.descr}>My main passion lies within <span className={styles.highlight}>Full-Stack Web</span> & <span className={styles.highlight}>Mobile Development</span>, and I have recently picked up an interest in <span className={styles.highlight}>Blockchain Development</span>. I enjoy building apps from the ground up, participating in Hackathons, and on the side, recreational ice-skating <i class="fa-sm fas fa-skating"></i>.</p>
        <p className={styles.descr}>I am also currently seeking new grad roles commencing at <span className={styles.highlight}>September 2023</span>.</p>
      </div>
    </div>
  )
}
