import React from 'react';
import { Link } from 'react-router-dom';
// styles
import styles from './Navigation.module.css';

export default function Navigation({ currentSection }) {
  let internships, projects, work, extracurriculars, awards;

  (currentSection) === 'internships'
    ? internships = `${styles.sectionLine} ${styles.currentSection}`
    : internships = styles.sectionLine;

  (currentSection) === 'projects'
    ? projects = `${styles.sectionLine} ${styles.currentSection}`
    : projects = styles.sectionLine;

  (currentSection) === 'work'
    ? work = `${styles.sectionLine} ${styles.currentSection}`
    : work = styles.sectionLine;

  (currentSection) === 'extracurriculars'
    ? extracurriculars = `${styles.sectionLine} ${styles.currentSection}`
    : extracurriculars = styles.sectionLine;

  (currentSection) === 'awards'
    ? awards = `${styles.sectionLine} ${styles.currentSection}`
    : awards = styles.sectionLine;

  return (
    <div className={styles.container}>
      <div className={styles.navGroup}>
        <div className={internships}/>
        <Link to='/internships' className={styles.link}>INTERNSHIPS</Link>
      </div>
      <div className={styles.navGroup}>
        <div className={projects}/>
        <Link to='/projects' className={styles.link}>PROJECTS</Link>
      </div>
      <div className={styles.navGroup}>
        <div className={work}/>
        <Link to='/work' className={styles.link}>WORK</Link>
      </div>
      <div className={styles.navGroup}>
        <div className={extracurriculars}/>
        <Link to='/extracurriculars' className={styles.link}>EXTRACURRICULARS</Link>
      </div>
      <div className={styles.navGroup}>
        <div className={awards}/>
        <Link to='/awards' className={styles.link}>AWARDS</Link>
      </div>
    </div>
  )
}
