import React from 'react';

import styles from './InfoCard.module.css';

export default function InfoCard({
  tech,
  iconClass,
  title,
  descr,
  date,
  link,
}) {
  return (
    <a className={styles.wrapper} href={link} target="_blank" rel="noreferrer">
      <div className={`${styles.container} ${styles.animated} ${styles.animatedFadeInUp} ${styles.fadeInUp}`}>
        <p className={styles.tech}><i class={iconClass}></i> {tech}</p>
        <h1 className={styles.title}>{title}</h1>
        <p className={styles.descr}>{descr}</p>
        <p className={styles.date}>{date}</p>
      </div>
    </a>
  )
}
