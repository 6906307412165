import React from 'react';
// contexts
import { useCardsContent } from '../../contexts/CardsContentContext';
// components
import InfoCard from '../InfoCard/InfoCard';
// styles
import styles from './Cards.module.css';

export default function Cards({ cards }) {
  // contexts
  const {
    internships,
    projects,
    workExp,
    extracurriculars,
    awards
  } = useCardsContent();

  // defn cards to map
  let cardsToMap = [];
  if (cards === 'internships') cardsToMap = internships;
  else if (cards === 'projects') cardsToMap = projects
  else if (cards ==='work') cardsToMap = workExp;
  else if (cards === 'extracurriculars') cardsToMap = extracurriculars;
  else if (cards === 'awards') cardsToMap = awards;

  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        {cardsToMap.map((card) => (
          <InfoCard
            tech={card.tech}
            iconClass={card.iconClass}
            title={card.title}
            descr={card.descr}
            date={card.date}
            link={card.link}
          />
        ))}
      </div>
    </div>
  );
}
